import React, {Component, Fragment} from "react";
import Slider from "react-slick";
import {slideSlick} from "../script";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {Link} from 'react-router-dom';
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import AboutTwo from "../component/HomeLayout/AboutTwo";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import TeamTwo from "../blocks/team/TeamTwo";

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--15',
        category: '',
        title: 'Technology Consulting Reimagined',
        description: 'A focus on speed and quality rather than billable hours.',
        buttonText: 'Contact Us',
        buttonLink: 'mailto:hello@taskforge.ca'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--15',
        category: '',
        title: 'A New Approach to Development',
        description: 'Challenging the traditional methods. Less Meetings. More Work.',
        buttonText: 'Contact Us',
        buttonLink: 'mailto:hello@taskforge.ca'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--15',
        category: '',
        title: 'Startup Mentality. Enterprise Friendly.',
        description: 'Move Fast. Deliver. Iterate.',
        buttonText: 'Contact Us',
        buttonLink: 'mailto:hello@taskforge.ca'
    }
]


class Home extends Component {
    render() {
        const PostList = BlogContent.slice(0, 3);
        return (
            <Fragment>
                <Helmet pageTitle="Home"/>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.svg" width='158'/>

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                // Start Single Slider 
                                <div
                                    className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                                    key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ?
                                                        <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ?
                                                        <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a
                                                        className="rn-button-style--2 btn-primary-color"
                                                        href={`${value.buttonLink}`}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                // End Single Slider
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area ptb--120" id='about'>
                    <AboutTwo/>
                </div>

                {/* End About Area */}
                <div className="service-area ptb--75  bg_image bg_image--3">
                    <div className="container" id='services'>
                        <ServiceTwo/>
                    </div>
                </div>

                {/* Start Team Area  */}
                {/*<div className="rn-team-wrapper ptb--120">*/}
                {/*    <div className="rn-team-area">*/}
                {/*        <div className="container">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-lg-12">*/}
                {/*                    <div className="section-title text-center mb--30">*/}
                {/*                        <h2>Our Team</h2>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <TeamTwo column="col-lg-3" teamStyle="" item="8" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Team Area  */}

                {/* Start CounterUp Area */}
                {/*<div className="rn-counterup-area pt--25 ptb--110 bg_color--1">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12">*/}
                {/*                <div className="section-title text-center">*/}
                {/*                    <h3 className="fontWeight500 mb--10">Our Fun Facts</h3>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <CounterOne />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End CounterUp Area */}

                {/* Start Testimonial Area */}
                {/*<div className="rn-testimonial-area bg_color--5 ptb--120">*/}
                {/*    <div className="container">*/}
                {/*        <Testimonial />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                {/*<div className="rn-brand-area brand-separation bg_color--5 ptb--120">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12">*/}
                {/*                <BrandTwo />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>

            </Fragment>
        )
    }
}

export default Home;