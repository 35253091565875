import React, { Component } from "react";

class AboutTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/anvil.png" alt="About"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">We know that when amazing people connect, amazing
                                            things will happen. This ideology is embraced by every one of us. We believe in the philosophy of
                                            "disagree & commit". What does that mean? It means we check our egos at the
                                            door and ensure the best ideas always win.
                                        </p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>A like-minded, high-performance group of individuals that come from
                                                a wide variety of tech backgrounds including start ups, consulting and enterprise.</p>
                                                <p>Solution focused rather than problem focused.</p>
                                                <p>Over 100+ combined years of industry experience as a team.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Why we are</h3>
                                                <p>We exist because we believe in a better way to do tech consulting.</p>
                                                <p>We believe in success and delivery based partnerships, not in maximizing billable hours.</p>
                                                <p>We know that success requires the work to be completed and that rarely happens in meeting rooms.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;