import React, {Component} from "react";
import { Link } from 'react-router-dom';
import {FiCode, FiLayers, FiUsers, FiBarChart} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiLayers/>,
        title: 'Full-Stack Development & Dev Ops',
        description: 'Websites, Mobile Apps, Dev Ops - our team has proven success delivering in all areas of development and infrastructure.'
    },
    {
        icon: <FiCode/>,
        title: 'Platform Development & Integration',
        description: 'Sometimes new isn\'t the right answer. Let us build and integrate with the existing platforms that are already working well with your business.'
    },
    {

        icon: <FiUsers/>,
        title: 'IT Leadership & Mentorship',
        description: 'Growing tech teams and managing IT projects is HARD. Our team knows how to do it successfully and lead your teams in a better direction.'
    },
    {
        icon: <FiBarChart/>,
        title: 'Organizational Growth & Growth Strategies',
        description: 'We have a proven track record helping organizations hit aggressive growth targets. We customize creative strategies to ensure success.'
    },
]

class ServiceTwo extends Component {
    render() {
        let title = 'Services',
            description = 'Here are some of the services we offer. Anything missing? Contact us, we\'d love to chat!';
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="mailto:hello@taskforge.ca" target='_blank' rel='noopener noreferrer'><span className="text">Contact Us</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ServiceTwo;
