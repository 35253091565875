import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let data = [
    {
        image: '/assets/images/team/aaron.png',
        title: 'Aaron Levine',
        designation: '',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/aaronjoshualevine/'
            },
        ]
    },
    {
        image: '/assets/images/team/adam.png',
        title: 'Adam Rehill',
        designation: '',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/adamrehill/'
            },
        ]
    },
    {
        image: '/assets/images/team/brian.png',
        title: 'Brian McKay',
        designation: '',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/brian-mckay-691a8422/'
            },
        ]
    },
    {
        image:'/assets/images/team/jules.png',
        title: 'Jules Van de Velde',
        designation: '',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/jules-van-de-velde-71148115/'
            },
        ]
    },
    {
        image: '/assets/images/team/chad.png',
        title: 'Chad Hurd',
        designation: '',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/chadhurd/'
            },
        ]
    },
    {
        image: '/assets/images/team/jesse.png',
        title: 'Jesse Vivanco',
        designation: '',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/jesse-vivanco-a6004956/'
            },
        ]
    },
];

export default data;